/**
 * Layout component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../utils/i18n";

import Header from "./header"
import Footer from "./footer"
import "../styles/global.scss"
import styles from "../styles/modules/layout.module.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={styles.content}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
