module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hoolaloom","short_name":"Hoolaloom","description":"Artisanal Weaving","start_url":"/","lang":"en-CA","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui","localize":[{"start_url":"/fr-ca/","lang":"fr-CA","name":"Hoolaloom","short_name":"Hoolaloom","description":"Tissage artisanal"}]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"hoolaloom","accessToken":"MC5YaHVCaFJVQUFDSUFKMVV4.77-9bFfvv707JO-_ve-_ve-_ve-_ve-_ve-_vV3vv712IDLvv73vv71t77-977-977-977-977-9Tu-_ve-_ve-_vVA0EA","defaultLang":"en-ca","langs":["en-ca","fr-ca"],"previews":false,"pages":[{"type":"Item","match":"/:lang?/pieces/:uid","path":"/pieces","component":"/opt/build/repo/src/templates/item.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"5017aad1-cd9d-45f5-a43b-c90e75567298","server":"https://eb-ackee.herokuapp.com","ignoreLocalhost":true,"detailed":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
