import React from "react"
import { useTranslation } from "react-i18next"
import styles from "../styles/modules/header.module.scss"
import LanguageSwitcher from "./LanguageSwitcher"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = () => {
  const { t } = useTranslation('header');

  return (
    <header className={styles.header}>
      <h1 className={styles.logo}>
        <AniLink cover bg="#EEE" to="/">Hoolaloom</AniLink>
      </h1>

      <nav className={styles.menu}>
        <ul className={styles.menu__links}>
          <li className={styles.menu__item}>
            <AniLink cover bg="#EEE" to="/" className={styles.menu__link}>
              {t('pieces')}
            </AniLink>
          </li>
        </ul>
        <LanguageSwitcher />
      </nav>
    </header>
  )
}

export default Header
