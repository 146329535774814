import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { useTranslation } from 'react-i18next'
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Hero from "../components/hero"
import styles from "../styles/modules/items.module.scss"

const Items = () => {
  const { i18n } = useTranslation('items')

  return (
    <StaticQuery
      query={graphql`
        query {
          prismic {
            allItems(
              sortBy: meta_firstPublicationDate_DESC
              last: 50
            ) {
              edges {
                node {
                  price
                  title
                  featured_image
                  featured_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1600, quality: 70) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  _meta {
                    lang
                    uid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const doc = data.prismic.allItems.edges.slice(0, 1).pop()
        const items = data.prismic.allItems.edges

        if (!doc) return null

        return (
          <section className={styles.page}>
            <Hero />
            <ul className={styles.items}>
              {items
                .filter(edge => edge.node._meta.lang.indexOf(i18n.language) > -1)
                .map(edge => {
                  const item = edge.node

                  return (
                    <li className={styles.item} key={`item-${item._meta.uid}`}>
                      <AniLink cover bg="#EEE" to={`${item._meta.lang !== 'en-ca' ? `/${item._meta.lang}` : ''}/pieces/${item._meta.uid}`}>
                        <div className={styles.item__image}>
                          <Img
                            fluid={item.featured_imageSharp.childImageSharp.fluid}
                            alt={RichText.asText(item.title)}
                          />
                        </div>
                        <div className={styles.item__info}>
                          <h2 className={styles.item__title}>{RichText.asText(item.title)}</h2>
                          <p className={styles.price}>{item.price.toLocaleString(item._meta.lang, { style: 'currency', currency: 'CAD', currencyDisplay: 'code' }).replace('.00', '').replace(',00', '')}</p>
                        </div>
                      </AniLink>
                    </li>
                  )
                })
              }
            </ul>
          </section>
        )
      }}
    />
  )
}

export default Items
