import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Items from "../components/items"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Items />
    </Layout>
  )
}

export default IndexPage
